@import url('https://fonts.googleapis.com/css2?family=Inter&family=Lato:wght@300;400;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: white;
}

li, a, button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
}

header {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.logo {
    width: 10%;
}

.navLinks {
    list-style: none;
}

.navLinks li {
    display: inline-block;
    padding: 0px 20px;
}

.navLinks li div {
    color: #333333;
    transition: all 0.3s ease 0s;
}

.navLinks li div:hover {
    color: #5727B0
}

button {
    color: #ECEFF1;
    padding: 9px 25px;
    background-color: rgba(87, 39, 176, 1);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

button:hover {
    background-color: rgba(87, 39, 176, 0.8);
}

/* CSS rules for iPhones in portrait orientation */
@media only screen and (min-width: 320px) and (max-width: 500px) and (orientation: portrait) {
    header {
        display: none;
    }
}

/* CSS rules for iPhones in landscape orientation */
@media only screen and (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
    header {
        display: none;
    }
}
  